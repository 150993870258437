export function isValidPassport(value) {
  const regex = new RegExp("[A-Za-z]{2}[\\d]{6}", "gm")

  const numberRegex = new RegExp("\\d{9}", "g")

  const match = regex.exec(value) || numberRegex.exec(value)

  if (!match) return false

  return true
}

<template>
  <z-card-modal
    persistent
    :title="$t('warrantyRegister.card_approval')"
    :opened="isLoading"
    :width="$vuetify.breakpoint.smAndDown ? 350 : 400"
  >
    <template #content>
      <div class="modal-content">
        <p class="body-text">
          {{ $t("warrantyRegister.processing_payment_modal") }}
        </p>

        <div class="loading-container">
          <animated-loading
            :height="120"
            :is-loading="isLoading"
          ></animated-loading>
        </div>
      </div>
    </template>
  </z-card-modal>
</template>

<script>
export default {
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    return {}
  },
}
</script>

<style lang="scss" scoped>
.modal-content {
  display: grid;
  margin-top: 15px;
  gap: 15px;

  .loading-container {
    display: grid;
    justify-content: center;
  }

  .buttons-container {
    display: grid;
    gap: 15px;
  }
}

@media screen and (min-width: 768px) {
  .modal-content .buttons-container {
    grid-template-columns: min-content min-content;
    justify-content: flex-end;
  }
}
</style>

<template>
  <z-card-modal
    persistent
    :title="$t('warrantyRegister.confirmed_payment_at_hotel')"
    :opened="isOpened"
    :width="$vuetify.breakpoint.smAndDown ? 350 : 400"
  >
    <template #content>
      <div class="modal-content">
        <div class="loading-container">
          <z-icon color="var(--darkgreen)" size="96px">
            $check_in_a_circle
          </z-icon>
        </div>

        <i18n
          tag="p"
          path="warrantyRegister.confirmed_hotel_payment_modal_description"
          class="body-text"
        >
          <template #hotelName>
            <b>{{ hotelName }}</b>
          </template>
        </i18n>

        <div class="buttons-container">
          <z-btn
            primary
            :is-loading="isLoading"
            :text="$t('warrantyRegister.return_to_guest_list')"
            @click="$emit('ok')"
          ></z-btn>
        </div>
      </div>
    </template>
  </z-card-modal>
</template>

<script>
export default {
  props: {
    isOpened: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    hotelName: {
      type: String,
      default: "Hotel Zoox",
    },
  },

  setup() {
    return {}
  },
}
</script>

<style lang="scss" scoped>
.modal-content {
  display: grid;
  margin-top: 15px;
  gap: 15px;

  .loading-container {
    display: grid;
    justify-content: center;
  }

  .buttons-container {
    display: grid;
    gap: 15px;
  }
}

@media screen and (min-width: 768px) {
  .modal-content .buttons-container {
    grid-template-columns: min-content min-content;
    justify-content: flex-end;
  }
}
</style>

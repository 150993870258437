<template>
  <z-card-modal
    persistent
    :title="$t('warrantyRegister.make_payment_at_hotel')"
    :opened="isOpened"
    :width="$vuetify.breakpoint.smAndDown ? 350 : 600"
  >
    <template #content>
      <div class="modal-content">
        <p
          class="body-text"
          v-html="$t('warrantyRegister.hotel_payment_modal_description')"
        ></p>

        <p class="body-text">
          {{ $t("warrantyRegister.hotel_payment_modal_confirmation") }}
        </p>

        <div class="buttons-container">
          <z-btn
            v-if="$vuetify.breakpoint.mdAndUp"
            :text="$t('warrantyRegister.cancel')"
            @click="$emit('back')"
          ></z-btn>
          <z-btn
            primary
            :text="$t('warrantyRegister.confirm')"
            @click="$emit('confirm')"
          ></z-btn>
          <z-btn
            v-if="$vuetify.breakpoint.smAndDown"
            :text="$t('warrantyRegister.cancel')"
            @click="$emit('back')"
          ></z-btn>
        </div>
      </div>
    </template>
  </z-card-modal>
</template>

<script>
export default {
  props: {
    isOpened: {
      type: Boolean,
    },
  },
}
</script>

<style lang="scss" scoped>
.modal-content {
  display: grid;
  margin-top: 15px;
  gap: 15px;

  .buttons-container {
    display: grid;
    gap: 15px;
  }
}

@media screen and (min-width: 768px) {
  .modal-content .buttons-container {
    grid-template-columns: min-content min-content;
    justify-content: flex-end;
  }
}
</style>

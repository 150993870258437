import countriesOptions from "@zoox-ui/data/countries.json"
import find from "lodash.find"

export const isValidMobilePhone =
  (countries = countriesOptions) =>
  (value) => {
    if (!value) {
      return true
    }
    let phone = value.split(" ")[0]
    if (!phone.includes("+")) phone = `+${phone}`

    const country = find(countries, { dial: phone })

    if (!country) {
      return false
    }
    if (country.mobileMask[0]) {
      return country.mobileMask[0].length === value.length
    }
  }

export const isValidPhone =
  (countries = countriesOptions) =>
  (value) => {
    if (!value) {
      return true
    }
    const phone = value.split(" ")[0]
    const country = find(countries, { dial: phone })

    if (!country) {
      return false
    }
    if (country.mask[0]) {
      return country.mask[0].length === value.length
    }
    return true
  }

import dayjs from "dayjs"
import customParseFormat from "dayjs/plugin/customParseFormat"

dayjs.extend(customParseFormat)

export const youngerThanEighteen = (value) => {
  const date = dayjs(value, "YYYY-MM-DD")
  const nowMinusEighteen = dayjs().subtract(18, "year")

  return date.isAfter(nowMinusEighteen)
}

export const olderThanEighteen = (value) => {
  let date = dayjs(value, "YYYY-MM-DD")

  const nowMinusEighteen = dayjs().subtract(18, "year")
  return date.isBefore(nowMinusEighteen)
}

export const isValidDate = (date) => {
  return dayjs(date, "YYYY-MM-DD", true).isValid()
}

export const isBeforeToday = (date) => {
  const formattedDate = dayjs(date, "YYYY-MM-DD")

  return formattedDate.isBefore(dayjs())
}

export const isYoungerThan110Years = (date) => {
  const formattedDate = dayjs(date, "YYYY-MM-DD")

  const age = dayjs().diff(formattedDate, "year", true)

  return age < 110
}

import dayjs from "dayjs"

export function isValidCreditCardDate(value) {
  const [expirationMonth, expirationYear] = value.split("/")
  if (expirationMonth == null || expirationYear == null) {
    return false
  }

  if (
    Number(expirationMonth) > 12 ||
    Number(expirationYear) < 10 ||
    Number(expirationYear) > 100
  ) {
    return false
  }
  const year = 2000 + Number(expirationYear)

  return dayjs()
    .set("year", year)
    .set("month", expirationMonth)
    .isAfter(dayjs())
}

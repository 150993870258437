<template>
  <div>
    <hotel-payment-modal
      :is-opened="isHotelOpened"
      @confirm="onConfirmHotel"
      @back="onClickBack"
    />
    <confirmed-hotel-payment-modal
      :is-opened="isConfirmedOpened"
      :is-loading="isLoading"
      @ok="next"
    />
  </div>
</template>

<script setup>
import { defineExpose, ref } from "vue"

import { useStoreAction } from "@/composables"

import ConfirmedHotelPaymentModal from "../components/modals/ConfirmedHotelPaymentModal.vue"
import HotelPaymentModal from "../components/modals/HotelPaymentModal.vue"

const isHotelOpened = ref(false)
const isConfirmedOpened = ref(false)

const onConfirmHotel = () => {
  isHotelOpened.value = false
  isConfirmedOpened.value = true
}

const onClickBack = () => {
  isHotelOpened.value = false
}

const { next, isLoading } = useStoreAction("flow/guest", "next")

/**
 * @public
 */
const openModal = () => {
  isHotelOpened.value = true
}

defineExpose({ openModal })
</script>

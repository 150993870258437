<!-- eslint-disable max-lines -->
<template>
  <div class="payment-form-wrapper">
    <div class="card">
      <z-physical-card
        has-cvv
        :number="computedCreditCard.number"
        :expiration-month="computedCreditCard.expirationMonth"
        :expiration-year="computedCreditCard.expirationYear"
        :cvv="cvvWithMask"
        :name="computedCreditCard.owner"
      >
        <template #logo>
          <img v-if="cardTypeImage" :src="cardTypeImage" />
        </template>
      </z-physical-card>

      <div class="card-info">
        <z-input
          id="card-number"
          hide-details="auto"
          mask="#### #### #### ####"
          inputmode="numeric"
          :label="$t('warrantyRegister.card_number')"
          :error-messages="getErrorMessages(v$.creditCard.number)"
          :value="creditCard.number"
          @input="$emit('change:card', { number: $event })"
        ></z-input>

        <z-input
          id="card-name"
          hide-details="auto"
          :label="$t('warrantyRegister.name_on_the_card')"
          :error-messages="getErrorMessages(v$.creditCard.owner)"
          :value="creditCard.owner"
          @input="$emit('change:card', { owner: $event })"
        ></z-input>

        <z-input
          id="cvv"
          hide-details="auto"
          mask="###"
          inputmode="numeric"
          label="cvv"
          :error-messages="getErrorMessages(v$.creditCard.cvv)"
          :value="creditCard.cvv"
          @input="$emit('change:card', { cvv: $event })"
        ></z-input>

        <z-input
          id="expiration-date"
          hide-details="auto"
          mask="##/##"
          inputmode="numeric"
          :label="$t('warrantyRegister.expiration_date')"
          :error-messages="getErrorMessages(v$.creditCard.expiration)"
          :value="creditCard.expiration"
          @input="$emit('change:card', { expiration: $event })"
        ></z-input>
      </div>
    </div>

    <span v-if="$vuetify.breakpoint.smAndDown" class="card-title">{{
      $t("warrantyRegister.billing_address")
    }}</span>

    <div class="address-info">
      <template v-if="$vuetify.breakpoint.smAndDown">
        <z-input
          id="cep"
          hide-details="auto"
          inputmode="numeric"
          mask="#####-###"
          :label="$t('warrantyRegister.zip_code')"
          :error-messages="getErrorMessages(v$.billingAddress.zipCode)"
          :value="billingAddress.zipCode"
          @input="$emit('change:address', { zipCode: $event })"
        ></z-input>
        <v-divider></v-divider>
      </template>

      <div class="address">
        <z-input
          v-if="$vuetify.breakpoint.mdAndUp"
          id="cep"
          hide-details="auto"
          inputmode="numeric"
          mask="#####-###"
          :label="$t('warrantyRegister.zip_code')"
          :error-messages="getErrorMessages(v$.billingAddress.zipCode)"
          :value="billingAddress.zipCode"
          @input="$emit('change:address', { zipCode: $event })"
        ></z-input>
        <z-input
          id="address"
          hide-details="auto"
          :class="{ 'span-2': $vuetify.breakpoint.smAndDown }"
          :label="$t('warrantyRegister.billing_address')"
          :error-messages="getErrorMessages(v$.billingAddress.address)"
          :value="billingAddress.address"
          @input="$emit('change:address', { address: $event })"
        ></z-input>
        <z-input
          id="number"
          hide-details="auto"
          inputmode="numeric"
          :label="$t('warrantyRegister.number')"
          :error-messages="getErrorMessages(v$.billingAddress.addressNumber)"
          :value="billingAddress.addressNumber"
          @input="$emit('change:address', { addressNumber: $event })"
        ></z-input>
        <z-input
          id="complement"
          hide-details="auto"
          :label="$t('warrantyRegister.complement')"
          :value="billingAddress.addressComplement"
          @input="$emit('change:address', { addressComplement: $event })"
        ></z-input>

        <z-input
          id="neighborhood"
          hide-details="auto"
          :class="{ 'span-2': $vuetify.breakpoint.smAndDown }"
          :label="$t('warrantyRegister.neighborhood')"
          :error-messages="getErrorMessages(v$.billingAddress.neighborhood)"
          :value="billingAddress.neighborhood"
          @input="$emit('change:address', { neighborhood: $event })"
        ></z-input>

        <z-autocomplete
          id="country"
          hide-details="auto"
          item-value="code"
          item-text="name"
          :class="{ 'span-2': $vuetify.breakpoint.smAndDown }"
          :items="countries"
          :label="$t('warrantyRegister.country')"
          :error-messages="getErrorMessages(v$.billingAddress.countryId)"
          :value="billingAddress.countryId"
          @change="$emit('change:address', { countryId: $event })"
        />

        <z-autocomplete
          v-if="billingAddress.countryId === 'BR'"
          id="state"
          hide-details="auto"
          item-text="name"
          item-value="id"
          :class="{ 'span-2': $vuetify.breakpoint.smAndDown }"
          :label="$t('warrantyRegister.state')"
          :error-messages="getErrorMessages(v$.billingAddress.stateId)"
          :value="billingAddress.stateId"
          :items="states"
          @change="$emit('change:address', { stateId: $event })"
        ></z-autocomplete>

        <z-input
          v-else
          id="state"
          :class="{ 'span-2': $vuetify.breakpoint.smAndDown }"
          :label="$t('warrantyRegister.state')"
          :error-messages="getErrorMessages(v$.billingAddress.state)"
          :value="billingAddress.stateName"
          @input="$emit('change:address', { stateName: $event })"
        />

        <z-autocomplete
          v-if="billingAddress.countryId === 'BR'"
          id="city"
          hide-details="auto"
          item-text="name"
          item-value="id"
          :class="{ 'span-2': $vuetify.breakpoint.smAndDown }"
          :label="$t('warrantyRegister.city')"
          :error-messages="getErrorMessages(v$.billingAddress.cityName)"
          :items="cities"
          :value="billingAddress.cityId"
          @change="$emit('change:address', { cityId: $event })"
        ></z-autocomplete>

        <z-input
          v-else
          id="city"
          :class="{ 'span-2': $vuetify.breakpoint.smAndDown }"
          :label="$t('warrantyRegister.city')"
          :error-messages="getErrorMessages(v$.billingAddress.cityName)"
          :value="billingAddress.cityName"
          @input="$emit('change:address', { cityName: $event })"
        />
      </div>
    </div>

    <template v-if="!hideMailSection">
      <span class="card-title">{{
        $t("warrantyRegister.send_proof_by_email")
      }}</span>

      <z-input
        type="email"
        hide-details="auto"
        label="E-mail"
        inputmode="email"
        :error-messages="getErrorMessages(v$.billingAddress.email)"
        :value="billingAddress.email"
        @input="$emit('change:address', { email: $event })"
      >
        <template v-if="!v$.billingAddress.email.$error" #append>
          <z-icon color="var(--lightgreen-500)"
            >$check_in_a_circle</z-icon
          ></template
        >
      </z-input>

      <i18n
        tag="p"
        class="body-text"
        path="warrantyRegister.send_proof_by_email_description"
      >
        <template #mail>
          <b>{{ billingAddress.email }}</b>
        </template>
      </i18n>
    </template>

    <div class="buttons-container">
      <z-btn
        v-if="$vuetify.breakpoint.mdAndUp"
        :text="$t('warrantyRegister.make_payment_at_hotel')"
        @click="$emit('back')"
      ></z-btn>
      <z-btn
        primary
        :is-loading="isLoading"
        :text="primaryBtnText"
        :disabled="v$.$invalid && v$.$dirty"
        @click="onClickNext"
      ></z-btn>
      <z-btn
        v-if="$vuetify.breakpoint.smAndDown"
        :text="$t('warrantyRegister.make_payment_at_hotel')"
        @click="$emit('back')"
      ></z-btn>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from "@vuelidate/core"
import { email, minLength, required } from "@vuelidate/validators"
import { first } from "lodash"
import { computed, toRefs } from "vue"

import { useValidations } from "@/composables"
import i18n from "@/i18n"
import { CardFlags } from "@/validators/creditCard"
import { isValidCardDate, isValidCreditCard } from "@/validators/index"

export default {
  name: "CreditCardForm",
  props: {
    creditCard: {
      type: Object,
      default: () => ({
        number: "",
        cvv: "",
        owner: "",
        expiration: "",
      }),
    },

    billingAddress: {
      type: Object,
      default: () => ({}),
    },

    cities: {
      type: Array,
      default: () => [],
    },

    states: {
      type: Array,
      default: () => [],
    },
    countries: {
      type: Array,
      default: () => [],
    },

    primaryBtnText: {
      type: String,
      default: i18n.tc("warrantyRegister.make_the_payment"),
    },

    hideMailSection: {
      type: Boolean,
      default: false,
    },

    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    let { creditCard, billingAddress } = toRefs(props)
    let { withI18nMessage, getErrorMessages } = useValidations()

    const cvvWithMask = computed(() => {
      const result = []
      const cvvString = creditCard.value.cvv.toString()

      for (let i = 0; i < 3; ++i) {
        if (typeof cvvString[i] === "undefined") {
          result.push("#")
        } else {
          result.push(cvvString[i])
        }
      }

      return result.join("")
    })

    const buildRules = () => {
      let creditCard = {
        number: {
          required: withI18nMessage(required),
          minLength: withI18nMessage(minLength(16)),
          isValidCreditCard: withI18nMessage(isValidCreditCard),
        },
        owner: {
          required: withI18nMessage(required),
          minLength: withI18nMessage(minLength(3)),
        },
        cvv: {
          required: withI18nMessage(required),
          minLength: withI18nMessage(minLength(3)),
        },
        expiration: {
          required: withI18nMessage(required),
          isValidDate: withI18nMessage(isValidCardDate),
        },
      }

      let billingAddress = {
        zipCode: {
          required: withI18nMessage(required),
        },
        address: {
          required: withI18nMessage(required),
        },
        addressNumber: {
          required: withI18nMessage(required),
        },
        neighborhood: {
          required: withI18nMessage(required),
        },
        cityName: {
          required: withI18nMessage(required),
        },
        stateId: {
          required: withI18nMessage(required),
        },
        countryId: {
          required: withI18nMessage(required),
        },
        email: {
          required: withI18nMessage(required),
          email: withI18nMessage(email),
        },
      }

      if (props.hideMailSection) {
        delete billingAddress.email
      }

      return {
        creditCard,
        billingAddress,
      }
    }

    const computedCreditCard = computed(() => {
      const number = creditCard.value.number.replace(/\s/g, "")

      const [expirationMonth, expirationYear] =
        creditCard.value.expiration.split("/")

      return {
        ...creditCard.value,
        number,
        expirationMonth,
        expirationYear,
      }
    })

    const cardTypeImage = computed(() => {
      const number = creditCard.value.number.replace(/\s/g, "")
      const brand = first(
        Object.keys(CardFlags).filter((flag) => {
          if (CardFlags[flag].test(number)) {
            return true
          }
          return false
        })
      )

      return brand != null
        ? require(`@/assets/images/credit-card-flags/${brand.toLowerCase()}.png`)
        : null
    })

    let v$ = useVuelidate(buildRules(), { creditCard, billingAddress })

    const onClickNext = () => {
      v$.value.$touch()

      if (!v$.value.$invalid) {
        emit("click:next")
      }
    }

    return {
      cardTypeImage,
      cvvWithMask,
      computedCreditCard,
      v$,
      getErrorMessages,
      onClickNext,
    }
  },
}
</script>

<style lang="scss" scoped>
.payment-form-wrapper {
  display: grid;
  gap: 15px;
}

.card {
  display: grid;
  justify-items: center;
  gap: 15px;
}

.span-2 {
  grid-column: span 2;
}

.card-info {
  display: grid;
  grid-template-areas:
    "number number number"
    "name name name"
    "date date cvv";
  gap: 15px;

  #card-number {
    grid-area: number;
  }

  #cvv {
    grid-area: cvv;
  }

  #card-name {
    grid-area: name;
  }

  #expiration-date {
    grid-area: date;
  }
}

.address-info {
  display: grid;
  gap: 15px;

  .address {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
  }
}

.buttons-container {
  display: grid;
  gap: 15px;
}

@media screen and (min-width: 960px) {
  .card {
    grid-template-columns: auto 1fr;
    gap: 15px;

    .card-info {
      width: 100%;
      grid-template-areas:
        "number number number"
        "name name name"
        "cvv date date";
    }
  }

  .address-info {
    .address {
      grid-template-columns: unset;
      grid-template-areas:
        "cep cep address address address number"
        "complement complement complement neighborhood neighborhood neighborhood"
        "country country state city city city";

      #cep {
        grid-area: cep;
      }

      #address {
        grid-area: address;
      }

      #number {
        grid-area: number;
      }

      #complement {
        grid-area: complement;
      }

      #neighborhood {
        grid-area: neighborhood;
      }

      #city {
        grid-area: city;
      }

      #state {
        grid-area: state;
      }

      #country {
        grid-area: country;
      }
    }
  }

  .buttons-container {
    grid-template-columns: min-content min-content;
    justify-content: flex-end;
  }
}
</style>
